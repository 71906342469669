import poker from "./assets/images/poker2.jpg";
import movie1 from "./assets/images/movie1.jpg";
import movie2 from "./assets/images/movie2.jpg";
import timebank from "./assets/images/timebank.jpg";
import posh from "./assets/images/POSH.jpg";
import bridge from "./assets/images/bridge.jpg";
import luccid from "./assets/images/luccid.jpg";
//import mark from "./assets/images/mark.jpg"
import almond from "./assets/images/almond.jpg";
import milkyway from "./assets/images/milkyway.jpg";
import logo from "./assets/images/logo.jpg";
import fullwhite from "./assets/images/Full_white.png";
import soulfitness from "./assets/images/soulfitness.png";
import vineyard from "./assets/images/vinyard.jpg";
import mark from "./assets/images/mark.png";
import craftmyportfolio from "./assets/images/craftmyportfolio.png";
import falcons from "./assets/images/falcons.svg"

const logotext = "Jake Clearwater";
const meta = {
  title: "Jake Clearwater",
  description:
    "I’m Jake Clearwater, a Full Stack Developer and UI/UX Designer from Dunedin, New Zealand. I hold a degree in Information Technology from Otago Polytechnic.",
};

const introdata = {
  title: "I'm Jake Clearwater",
  animated: {
    first: "FullStack Developer",
    second: "UI/UX Designer",
    third: "Have a look around",
    fourth: "Get in contact today!",
    fifth: "Currently I am employed but I am always open to new opportunities!",
  },
  description:
    "Based in Dunedin, New Zealand, I am a Full Stack Developer and UI/UX Designer. In my spare time I enjoy Basketball and a few video games.",
  your_img_url: logo,
};

const dataabout = {
  title: "A bit about myself",
  aboutme:
    "Born in Dunedin, I returned for university after spending my childhood in Alexandra. I have a passion for coding and design, and I am always looking to learn new things. I am a big Basketball fan, with dreams of one day making the NBA ultimately crushed by the lack of ability, height and genetics I settled for IT.",
};
const worktimeline = [
  {
    jobtitle: "Junior System Administrator",
    where: "Forsyth Barr",
    date: "2024 - Present",
  },
  {
    jobtitle: "Freelance Web Developer",
    where: "Jake Clearwater Web Design",
    date: "2023 - Present",
  },
  {
    jobtitle: "Bachelor of Information Technology",
    where: "Otago Polytechnic",
    date: "2021 - 2023",
  },
  {
    jobtitle: "Platform and Technology Analyst",
    where: "One Picture",
    date: "2021 - 2023",
  },
  {
    jobtitle: "Orchard Hand",
    where: "Clyde Orchards",
    date: "2017 - 2021",
  },
  {
    jobtitle: "Dunstan High School",
    where: "Alexandra, New Zealand",
    date: "2016 - 2020",
  },
  {
    jobtitle: "Born",
    where: "Dunedin, New Zealand",
    date: "2002",
  },
];

const skills = [
  {
    name: "React",
    value: 95,
  },
  {
    name: "Javascript",
    value: 90,
  },
  {
    name: "Node.js",
    value: 90,
  },
  {
    name: "Bash",
    value: 85,
  },
  {
    name: "Linux",
    value: 80,
  },
  {
    name: "Agile Methodologies",
    value: 90,
  },
  {
    name: "API Develop and Use",
    value: 90,
  },
  {
    name: "SQL",
    value: 80,
  },
  {
    name: "MongoDB",
    value: 90,
  },
  {
    name: "Firebase",
    value: 90,
  },
  {
    name: "TypeScript",
    value: 80,
  },
  {
    name: "HTML",
    value: 95,
  },
  {
    name: "CSS",
    value: 95,
  },
  {
    name: "SEO",
    value: 80,
  },
  {
    name: "UI/UX Design",
    value: 90,
  },
  {
    name: "Patience with stupid people",
    value: -5,
  },
];

const services = [
  {
    title: "UI & UX Design",
    description:
      "I can make your website both pretty and functional. I can take your website to the next level and maintain it on short or long term contracts.",
  },
  {
    title: "Web Apps",
    description:
      "Got a great idea? Don't know how to make it a reality? I can help you make your idea a reality. With my skills in both front and back end development, I can make a functional web app to suit your needs.",
  },
  {
    title: "Website Refresh",
    description:
      "Got a website that needs a refresh? I can help you make your website look modern and fresh. I can also help you with SEO and other marketing strategies to help your website get more traffic.",
  },
];

const dataportfolio = [
  {
    img: timebank,
    description: "TimeBank",
    link: "https://www.mctimebank.nz/",
  },
  {
    img: fullwhite,
    description: "Octagon Events",
    link: "https://octagonevents.co.nz/",
  },
  {
    img: vineyard,
    description: "Wild Irishmen Wines Accommodation",
    link: "https://wildirishman.netlify.app/",
  },
  {
    img: falcons,
    description: "Andy Bay Falcons Basketball Club",
    link: "https://www.andybayfalcons.co.nz/"
  }
];

const dataportfolio2 = [
  {
    //get images from asssets folder
    img: mark,
    description: "Mark Weatherall Metal Work",
  },
  {
    img: almond,
    description: "Almond Court Motel",
  },
  
];

const contactConfig = {
  YOUR_EMAIL: "jakeclearwater7@gmail.com",
  YOUR_FONE: "(+64) 027 374 0021",
  description:
    "I am currently not available for large projects but lets chat anyway! Connect with me on your preferred platform above or to the left.",
  // create an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "service_3p7a1xp",
  YOUR_TEMPLATE_ID: "template_fn2o5nr",
  YOUR_USER_ID: "5CxH9IarSw_naIhgY",
};

const socialprofils = {
  github: "https://github.com/jakeclearwater",
  linkedin: "https://www.linkedin.com/in/jake-clearwater-222181224/",
};
export {
  meta,
  dataabout,
  dataportfolio,
  dataportfolio2,
  worktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
};
